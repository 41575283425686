import React from 'react'
import { Link } from 'gatsby'

import { Layout, SEO } from '../components/common'
// import { MetaData } from '../components/common/meta'

const ExplorePage = () => (
    <React.Fragment>
        <SEO title='Personal data explorations' description='Some of the quantified self analysis that I do on my own data is available for visitors of this website.' />
        <Layout>
            <div className="container">
                <article className="content">
                    <h1 className="content-title">Personal data explorations</h1>
                    <section className="content-body">
                        <p>
                            Some of the analysis that I do on my own data is available for visitors of this website.
                        </p>
                        <p>
                            You can play around with some example data sets for each exploration.
                        </p>
                        <p>
                            If you choose to explore your own data, it won't be uploaded to any servers.<br/>
                            All data will remain in your browser window and will be cleared upon refreshing or leaving the page.
                        </p>

                        <h3>Available Explorations</h3>
                        <Link to="/exploration/compare/">Cross-metric comparisons</Link>
                    </section>
                </article>
            </div>
        </Layout>
    </React.Fragment>
)

export default ExplorePage
